//ua判定
var ua = UAParser()
var HTML = document.getElementsByTagName('html')[0];
var uaString = {
	browserName: ua.browser.name.toLowerCase().replace(' ', '-'),
	browserVersion: ua.browser.major,
	browserEngine: ua.engine.name.toLowerCase().replace(' ', '-'),
	osName: ua.os.name.toLowerCase().replace(' ', '-'),
	type: (typeof ua.device.type !== 'undefined') ? ua.device.type.toLowerCase().replace(' ', '-') : 'laptop'
}
HTML.dataset.browser = uaString.browserName
HTML.dataset.browserversion = uaString.browserVersion
HTML.dataset.browserengine = uaString.browserEngine
HTML.dataset.os = uaString.osName
HTML.dataset.type = uaString.type

document.addEventListener('DOMContentLoaded', function () {
	objectFitImages();
	smoothScroll();
})
window.addEventListener('load', function () {
})
window.addEventListener('scroll', function () {
});


function smoothScroll() {
	var links = document.querySelectorAll('a[href^="#"]');
	var speed = 200;          // スクロールスピード   
	var divisor = 100;        // 分割数
	var tolerance = 5;        // 許容誤差
	var headerHeight = 0;     // 固定ヘッダーがある場合はその高さ分ずらす
	var interval = speed / divisor;
	for (var i = 0; i < links.length; i++) {
		links[i].addEventListener('click', function (e) {
			e.preventDefault();
			var nowY = window.pageYOffset;
			var href = e.currentTarget.getAttribute('href');
			if (e.currentTarget.classList.contains("js-pagetop")) {
			}
			var target;
			if (e.currentTarget.classList.contains("js-pagetop")) {
				target = document.body;
			} else {
				target = document.querySelector(href);
			}
			if (target != null) {
				var targetRectTop = target.getBoundingClientRect().top;
				var targetY = targetRectTop + nowY - headerHeight;
				var minY = Math.abs((targetY - nowY) / divisor);
				doScroll(minY, nowY, targetY, tolerance, interval);
			}
		});
	}
}

function doScroll(minY, nowY, targetY, tolerance, interval) {
	var toY;
	if (targetY < nowY) {
		toY = nowY - minY;
	} else {
		toY = nowY + minY;
	}
	window.scrollTo(0, toY);
	if (targetY - tolerance > toY || toY > targetY + tolerance) {
		window.setTimeout(doScroll, interval, minY, toY, targetY, tolerance, interval);
	} else {
		return false;
	}
}

